import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IUserMeResult, UserClient } from '@clp/api';
import { AuthService } from '@clp/auth';
import { EMPTY, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginResolver  {
  constructor(private authSvc: AuthService, private userClient: UserClient, private router: Router) {

  }

  private getMeObservable(){
    return this.userClient.getMe2().pipe(
      map(x => x.result!),
      switchMap(x => {
        if(!x) {
          this.router.navigate(['/error', 'Unable to acquire login data'])
          return EMPTY;
        }

        return of(x);
      })
    )
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IUserMeResult | null> | Promise<IUserMeResult> | IUserMeResult {
    return this.authSvc.isAuthenticated$.pipe(
      switchMap(x => {
        if(x.isAuthenticated) {
          return this.getMeObservable();
        }

        return of(null);
      })
    );
  }
}
