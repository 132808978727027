import { Component } from '@angular/core';
import { FaConfig } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'clp-root',
  template: `<clp-layout-topbar role="navigation" aria-label="Global Navigation"></clp-layout-topbar><router-outlet></router-outlet>`
})
export class AppComp {
  constructor(faConfig: FaConfig) {
    faConfig.autoAddCss = false;
  }
}
