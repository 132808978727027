export class Logger {
  constructor(public readonly sourceContext: string) {}

  private formatMessage(message: string) {
    return `[inf] [${this.sourceContext}] ${message}`;
  }

  public info(message?: any, ...args: any[]) {
    message = this.formatMessage(message);
    !!args && args.length > 0
      ? console.log(message, args)
      : console.log(message);
  }
}

export class LogHelper {
  public static getLogger(sourceContext: string) {
    return new Logger(sourceContext);
  }
}
