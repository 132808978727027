import { InjectionToken } from '@angular/core';

const NG_DEV_MODE = typeof ngDevMode !== 'undefined' && ngDevMode;
const ɵUSER_OPTIONS = new InjectionToken(NG_DEV_MODE ? 'USER_OPTIONS' : '', { providedIn: 'root', factory: () => undefined });
const ɵNGXS_ROUTER_PLUGIN_OPTIONS = new InjectionToken(NG_DEV_MODE ? 'NGXS_ROUTER_PLUGIN_OPTIONS' : '', { providedIn: 'root', factory: () => ({}) });
function ɵcreateRouterPluginOptions(options) {
    return {
        navigationActionTiming: (options && options.navigationActionTiming) || 1 /* NavigationActionTiming.PreActivation */
    };
}

/**
 * Generated bundle index. Do not edit.
 */

export { ɵNGXS_ROUTER_PLUGIN_OPTIONS, ɵUSER_OPTIONS, ɵcreateRouterPluginOptions };

