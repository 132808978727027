import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ApiModule } from '@clp/api';
import { AppAuthModule } from '@clp/auth';
import { configInitializer, ConfigService, LAUNCHPOINT_SPA_CONFIG } from '@clp/config';
import { LayoutModule } from '@clp/layout';
import { AppStateModule } from '@clp/state';

import { AppRoutingModule } from './app-routing.m';
import { AppComp } from './app.comp';

@NgModule({
  declarations: [
    AppComp,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAuthModule,
    AppStateModule,
    FontAwesomeModule,
    LayoutModule,
    ApiModule
  ],
  providers: [
    configInitializer,
    {
      provide: LAUNCHPOINT_SPA_CONFIG,
      useFactory: (cfgSvc: ConfigService) => cfgSvc.currentConfig,
      deps: [ConfigService]
    },
  ],
  bootstrap: [AppComp]
})
export class AppModule { }
