import { NgModule } from "@angular/core";
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { CommonModule } from "@angular/common";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsRouterPluginModule, RouterStateSerializer } from "@ngxs/router-plugin";
import { environment } from "src/environments/environment";
import { CustomRouterStateSerializer } from "./app-router.state";
import { AppState } from "./app-state";


@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot([AppState], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true
      }
    }),
    NgxsStoragePluginModule.forRoot({
      keys: [
        AppState
      ],
      storage: StorageOption.SessionStorage,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
  ],
  exports: [
    NgxsModule,
    NgxsStoragePluginModule,
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    }
  ]
})
export class AppStateModule {

}
