import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';

import { AuthHrdGuard, UnauthorizedComp } from '@clp/auth';
import { LayoutComp } from '@clp/layout';
import { AuthRootGuard } from './auth/auth-root.guard';
import { LoginRoutePath } from '@clp/u-login';
import { LandingRoutePath } from '@clp/u-general';
import { LoginResolver } from './u-login/login.resolver';

export const appRoutes = {
  loggedInUser: 'u',
  unauthorized: 'unauthorized',
};

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthRootGuard],
    resolve: {
      userLogin: LoginResolver
    },
    children: [
      {
        path: appRoutes.loggedInUser,
        component: LayoutComp,
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () => import('@clp/u-general').then(x => x.UGeneralModule)
      },
      {
        path: appRoutes.unauthorized,
        component: UnauthorizedComp
      },
      {
        path: LoginRoutePath,
        canActivate: [AuthHrdGuard],
        loadChildren: () => import('@clp/u-login').then(x=>x.ULoginModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: LandingRoutePath
      },
      {
        path: '**',
        redirectTo: LandingRoutePath
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
