import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserClient, UserPreferenceClient } from '@clp/api';
import { AuthUserLoggedIn } from '@clp/state';
import { LandingRoute } from '@clp/u-general';
import { LoginRoute } from '@clp/u-login';
import { Store } from '@ngxs/store';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { defer, map, of, switchMap } from 'rxjs';
import { setHrd } from './auth.initializer';
import { AuthStorage } from './auth.storage';
import { AuthService } from './auth.svc';
import { AuthUtil } from './auth.util';
import { LogHelper, Logger } from '@clp/util/log-helper';

@Injectable({ providedIn: 'root' })
export class AuthRootGuard {
  private readonly logger: Logger;

  constructor(
    private oidcSvc: OidcSecurityService,
    private r: Router,
    private authSvc: AuthService,
    private storage: AuthStorage,
    private store: Store,
    private userClient: UserClient,
    private userPref: UserPreferenceClient
  ) {
    this.logger = LogHelper.getLogger('authGuard');
  }

  private getRedirect() {
    let value = this.storage.getStoredRedirect();
    if (!value) {
      return value;
    }

    this.storage.removeStoredRedirect();
    if (value == '' || value == '/') {
      value = LandingRoute;
    }
    return value;
  }

  private handleAuthenticationComplete(
    upn: string,
    state: RouterStateSnapshot
  ) {
    if (upn?.includes('consilio.com')) {
      return of({}).pipe(
        map((x) => {
          let redirect = this.getRedirect();
          if (!!redirect) {
            return this.r.createUrlTree([redirect]);
          }

          return true;
        })
      );
    }

    return this.userPref.getMfaPreference().pipe(
      map((resp) => {
        if (state.url.startsWith(LoginRoute)) {
          return true;
        }

        if (resp.result === '') {
          return this.r.createUrlTree(['login', 'mfaPreference']);
        }

        let redirect = this.getRedirect();
        if (!!redirect) {
          return this.r.createUrlTree([redirect]);
        }

        return true;
      })
    );
  }

  private handleAuthenticationSuccess(
    loginResp: LoginResponse,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.userClient.getMe2().pipe(
      map((x) => x.result),
      switchMap((x) => {
        const userInfo = {
          idpName: x?.provider?.displayName,
          ...loginResp.userData,
        };

        this.store.dispatch(new AuthUserLoggedIn(userInfo));
        const upn = loginResp.userData?.upn?.toLowerCase();
        return this.handleAuthenticationComplete(upn, state);
      })
    );
  }

  private async handleNotAuthenticated(state: RouterStateSnapshot) {
    if (state.url.startsWith(LoginRoute)) {
      this.logger.info('Redirect target is login');
      return true;
    }

    this.storage.setStoredRedirect(state.url);
    return this.r.createUrlTree([LoginRoute]);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hrdParam = AuthUtil.getHrdQueryParameter(route.queryParams);
    if (!!hrdParam) {
      setHrd(hrdParam);
      this.authSvc.login();
      return false;
    }

    return this.oidcSvc.checkAuth().pipe(
      switchMap((x) => {
        if (x.isAuthenticated) {
          this.logger.info('Success');
          return this.handleAuthenticationSuccess(x, route, state);
        }

        return defer(() => this.handleNotAuthenticated(state));
      })
    );
  }
}
