import { CSP_NONCE, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.m';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const nonce = (
  document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement
)?.content;


const handleBootError = (err: any) => {
  console.error(err);
  const rootElem = document.querySelector('clp-root') as HTMLDivElement;
  rootElem.style.backgroundColor = 'gray';
  rootElem.style.color = 'white';

  const errorMessageElem = document.createElement('div');
  errorMessageElem.style.padding = '2rem';
  errorMessageElem.style.fontSize = '16pt';
  errorMessageElem.style.fontWeight = '400';

  errorMessageElem.innerHTML = `
  <svg fill="white" height="64" width="64" viewbox="0 0 64 64" style="margin-left: 12px;">
    <g id="warning">
      <path d="M32.427,7.987c2.183,0.124 4,1.165 5.096,3.281l17.936,36.208c1.739,3.66 -0.954,8.585 -5.373,8.656l-36.119,0c-4.022,-0.064 -7.322,-4.631 -5.352,-8.696l18.271,-36.207c0.342,-0.65 0.498,-0.838 0.793,-1.179c1.186,-1.375 2.483,-2.111 4.748,-2.063Zm-0.295,3.997c-0.687,0.034 -1.316,0.419 -1.659,1.017c-6.312,11.979 -12.397,24.081 -18.301,36.267c-0.546,1.225 0.391,2.797 1.762,2.863c12.06,0.195 24.125,0.195 36.185,0c1.325,-0.064 2.321,-1.584 1.769,-2.85c-5.793,-12.184 -11.765,-24.286 -17.966,-36.267c-0.366,-0.651 -0.903,-1.042 -1.79,-1.03Z" style="fill-rule:nonzero;"/>
      <path d="M33.631,40.581l-3.348,0l-0.368,-16.449l4.1,0l-0.384,16.449Zm-3.828,5.03c0,-0.609 0.197,-1.113 0.592,-1.514c0.396,-0.4 0.935,-0.601 1.618,-0.601c0.684,0 1.223,0.201 1.618,0.601c0.395,0.401 0.593,0.905 0.593,1.514c0,0.587 -0.193,1.078 -0.577,1.473c-0.385,0.395 -0.929,0.593 -1.634,0.593c-0.705,0 -1.249,-0.198 -1.634,-0.593c-0.384,-0.395 -0.576,-0.886 -0.576,-1.473Z" style="fill-rule:nonzero;"/>
    </g>
  </svg>
  <div>Consilio | Launchpoint</div>
  Application bootstrap failed.<br/><br/>Please contact <a style="color: #2c2c2c; font-weight: bold; text-decoration: underline;" href="mailto:helpdesk@consilio.com">helpdesk@consilio.com</a> for further support.`;
  rootElem?.appendChild(errorMessageElem);
}

console.log(`Loading CSP_NONCE=${nonce}`);

const platform = platformBrowserDynamic()
const bootPromise = platform.bootstrapModule(AppModule, {
  providers: []
});
bootPromise.catch(err => handleBootError(err));
